//Components
import { Link } from "../common";
import { StyledNavLink } from "./styles";

const NavLink = ({ href, title, ...rest }) => {
  return (
    <StyledNavLink component={Link} href={href} underline="none" {...rest}>
      {title}
    </StyledNavLink>
  );
};

export default NavLink;
