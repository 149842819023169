import { Grid, Box } from "@material-ui/core";

//Components
import { Section, Logo } from "../common";
import ContactUs from "./ContactUs";
import ContactForm from "./ContactForm";
import Media from "./Media";
import FollowUs from "./FollowUs";

const Footer = () => {
  return (
    <Section bgcolor="common.black" py={4}>
      <Box clone color="background.paper">
        <Grid container alignContent="center" justify="center" spacing={1}>
          <Grid item xs={12} md={2}>
            <Logo src="/images/kfas_logo-light.svg" />
          </Grid>
          <Grid item xs={6} md={2}>
            <Media />
          </Grid>
          <Grid item xs={6} md={2}>
            <FollowUs />
          </Grid>
          <Grid item xs={12} md={2}>
            <ContactUs />
          </Grid>
          <Grid item xs={12} md={4}>
            <ContactForm />
          </Grid>
        </Grid>
      </Box>
    </Section>
  );
};

export default Footer;
