import { useState } from "react";
import { Grid } from "@material-ui/core";

//Translation
import { useTranslation } from "../../i18n";

//Components
import { DarkTextField, SubmitButton } from "./styles";

const ContactForm = () => {
  const { t } = useTranslation("footer");
  const [form, setForm] = useState({ email: "", subject: "", message: "" });

  const handleChange = (e) =>
    setForm({ ...form, [e.target.name]: e.target.value });

  const handleSubmit = () => alert(JSON.stringify(form));

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <DarkTextField
            name="email"
            required
            id="email"
            fullWidth
            size="small"
            variant="outlined"
            placeholder={t("contactForm.email")}
            onChange={handleChange}
          />
        </Grid>
        <Grid item>
          <DarkTextField
            name="subject"
            required
            id="subject"
            fullWidth
            size="small"
            variant="outlined"
            placeholder={t("contactForm.subject")}
            onChange={handleChange}
          />
        </Grid>
        <Grid item>
          <DarkTextField
            name="message"
            multiline
            fullWidth
            variant="outlined"
            rows={4}
            placeholder={t("contactForm.message")}
            onChange={handleChange}
          />
        </Grid>
        <Grid item>
          <SubmitButton
            variant="contained"
            color="primary"
            size="large"
            type="submit"
          >
            {t("contactForm.send")}
          </SubmitButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default ContactForm;
