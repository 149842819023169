import { Grid, Typography } from "@material-ui/core";
import { Link } from "../common";
//Translation
import { useTranslation } from "../../i18n";
const Media = () => {
  const { t } = useTranslation("footer");

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="subtitle1">{t("media")}</Typography>
      </Grid>
      <Grid item>
        <Link color={"inherit"} href="/events">
          <Typography>{t("events")}</Typography>
        </Link>
      </Grid>
      <Grid item>
        <Link color={"inherit"} href="/gallery">
          <Typography>{t("gallery")}</Typography>
        </Link>
      </Grid>
      <Grid item>
        <Link
          color={"inherit"}
          href="https://drive.google.com/file/d/1GRJTMKnOVfMcJNuvwDjuc1mPjblzgn9V/view"
          target="_blank"
        >
          <Typography>{t("year_book")}</Typography>
        </Link>
      </Grid>
    </Grid>
  );
};

export default Media;
