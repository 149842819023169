import { Button, TextField } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

export const DarkTextField = styled(TextField)(({ theme }) => ({
  background: "#17181B",
  borderRadius: 6,
  "& .MuiOutlinedInput-root": {
    color: "white",
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.common.black,
    },
  },
}));

export const SubmitButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  color: theme.palette.background.paper,
}));
