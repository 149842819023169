import { Grid, Typography } from "@material-ui/core";
import { Link } from "../common";
//Translation
import { useTranslation } from "../../i18n";

const FollowUs = () => {
  const { t } = useTranslation("footer");

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="subtitle1">{t("follow_us")}</Typography>
      </Grid>
      <Grid item>
        <Link color={"inherit"} href="#">
          <Typography>Twitter</Typography>
        </Link>
      </Grid>
      <Grid item>
        <Link color={"inherit"} href="#">
          <Typography>Instagram</Typography>
        </Link>
      </Grid>
      <Grid item>
        <Link color={"inherit"} href="#">
          <Typography>Facebook</Typography>
        </Link>
      </Grid>
      <Grid item>
        <Link color={"inherit"} href="#">
          <Typography>LinkedIn</Typography>
        </Link>
      </Grid>
    </Grid>
  );
};

export default FollowUs;
