import { Grid, Typography } from "@material-ui/core";
import { Link } from "../common";
//Translation
import { useTranslation } from "../../i18n";

const ContactUs = () => {
  const { t } = useTranslation("footer");

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="subtitle1">{t("contact_us")}</Typography>
      </Grid>
      <Grid item>
        <Link color={"inherit"} href="tel:+96512345678">
          <Typography>(+965) 12345678</Typography>
        </Link>
      </Grid>
      <Grid item>
        <Link color={"inherit"} href="mailto:kfas@kfas.com">
          <Typography>kfas@kfas.com</Typography>
        </Link>
      </Grid>
    </Grid>
  );
};

export default ContactUs;
