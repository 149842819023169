import { Button } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

export const StyledNavLink = styled(Button)(({ theme }) => ({
  fontWeight: theme.typography.subtitle2.fontWeight,
  fontSize: theme.typography.subtitle2.fontSize,
  color: theme.palette.text.primary,
  padding: "10px 8px",

  "&.active": {
    color: theme.palette.primary.main,
  },

  "&:hover": {
    color: theme.palette.primary.dark,
    cursor: "pointer",
  },
}));
