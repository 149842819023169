import { useState, useEffect, useContext } from "react";
import Head from "next/head";
import "@fortawesome/fontawesome-free/js/all";

//Translation
import { appWithTranslation } from "../i18n";
import { I18nContext } from "next-i18next";

// Apollo
import { useApollo } from "../apollo";
import { ApolloProvider, gql } from "@apollo/client";

//MaterialUI
import { ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline, Box } from "@material-ui/core";
import theme from "../styles/theme";

// Components
import { Loading } from "../components/common";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

// Hooks
import { useLocalStorage } from "../hooks";

// Sentry
import * as Sentry from "@sentry/node";
import { RewriteFrames } from "@sentry/integrations";
import getConfig from "next/config";

const CAMPAIGN_ACTIVE = gql`
  query {
    campaignActive
  }
`;

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const {
    serverRuntimeConfig: { rootDir },
  } = getConfig();
  const distDir = `${rootDir}/.next`;

  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, "app:///_next");
          return frame;
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

function App({ Component, pageProps }) {
  const apolloClient = useApollo(pageProps.initialApolloState);
  const { i18n } = useContext(I18nContext);
  const [locale, _] = useLocalStorage("locale", "ar");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  useEffect(() => {
    if (i18n.language !== locale) i18n.changeLanguage(locale);
  }, []);

  useEffect(() => {
    if (loading) setLoading(locale !== i18n.language);
  }, [locale, i18n.language]);

  const muiTheme = theme(i18n.language === "en");

  return (
    <ApolloProvider client={apolloClient}>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <link rel="stylesheet" href="/fonts/fonts.css" />
      </Head>

      <ThemeProvider theme={muiTheme}>
        <CssBaseline />
        {loading ? (
          <Loading />
        ) : (
          <div dir={muiTheme.direction} style={{ overflowX: "hidden" }}>
            <Navbar />
            <Box mt={15}>
              <Component {...pageProps} />
            </Box>
            <Footer />
          </div>
        )}
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default appWithTranslation(App);
