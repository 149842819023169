import { useState } from "react";

//Translation
import { useTranslation } from "../../i18n";

//Components
import Topbar from "./Topbar";
import MobileNav from "./MobileNav";

const Navbar = () => {
  const { t } = useTranslation("navbar");
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const navItems = [
    {
      href: "/events",
      title: t("events"),
    },
    {
      href: "/gallery",
      title: t("gallery"),
    },
    {
      href: "/teams",
      title: t("team"),
    },
  ];

  return (
    <>
      <Topbar
        navItems={navItems}
        onMobileNavOpen={() => setMobileNavOpen(true)}
        isMobileNavOpen={isMobileNavOpen}
      />
      <MobileNav
        navItems={navItems}
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
    </>
  );
};

export default Navbar;
