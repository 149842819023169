import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

const theme = (isEnglish) =>
  responsiveFontSizes(
    createMuiTheme({
      direction: isEnglish ? "ltr" : "rtl",
      palette: {
        common: {
          black: "#232529",
          white: "#fff",
        },
        primary: { main: "#DC6E37", dark: "#DA4A03" },
        kaleGreen: "#6BB864",
        foqluiGreen: "#f4f9e4",
        mirinda: "#E88D41",
        text: {
          primary: "#25283F",
        },
        background: {
          default: "#ebf4d0",
        },
      },
      typography: {
        fontFamily: isEnglish ? "Gotham, Arial" : "Dinar",
        h1: {
          fontSize: "3.25rem",
          fontWeight: "bold",
        },
        h2: {
          fontSize: "2.25rem",
          fontWeight: 500,
        },
        h3: {
          fontSize: "1.75rem",
          fontWeight: "bold",
        },
        h4: {
          fontSize: "1.5rem",
          fontWeight: 500,
        },
        h5: {
          fontSize: "1.5rem",
        },
        subtitle1: {
          fontSize: "1.125rem",
          fontWeight: 500,
        },
        subtitle2: {
          fontSize: "1rem",
          fontWeight: 500,
        },
        button: {
          textTransform: "none",
          fontSize: "1rem",
          fontWeight: "bold",
        },
      },
    })
  );

export default theme;
