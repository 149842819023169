import {
  AppBar,
  Hidden,
  IconButton,
  Toolbar,
  Grid,
  Box,
  Container,
} from "@material-ui/core";

//Icons
import { Menu, Close } from "@material-ui/icons";

//Components
import { ElevationScroll, Link, Logo } from "../common";
import NavLink from "./NavLink";
import LanguageButton from "./LanguageButton";
import { useRouter } from "next/router";

const Topbar = ({ navItems, onMobileNavOpen, isMobileNavOpen }) => {
  const router = useRouter()
  const navLinks = navItems.map((item) => (
    <Grid key={item.title} item>
      <NavLink {...item} />
    </Grid>
  ));

  console.log(router.pathname)

  return (
    <ElevationScroll>
      <AppBar position="fixed">
        <Box pt={5} pb={2} style={{background: router.pathname !=="/events"?"linear-gradient(0.25turn, #eff3c6 ,60%, #9CD99E)":"white"}}>
          <Container>
            <Toolbar disableGutters>
              <Link href="/">
                <Logo src="/images/kfas_logo.svg" />
              </Link>
              <Box flexGrow={1} />

              <Hidden smDown>
                <Grid
                  container
                  spacing={3}
                  alignItems="center"
                  justify="flex-end"
                >
                  {navLinks}
                  <Grid item>
                    <LanguageButton />
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <IconButton color="default" onClick={onMobileNavOpen}>
                  {isMobileNavOpen ? <Close /> : <Menu />}
                </IconButton>
              </Hidden>
            </Toolbar>
          </Container>
        </Box>
      </AppBar>
    </ElevationScroll>
  );
};

export default Topbar;
