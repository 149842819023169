import { Link, Box } from "@material-ui/core";

// Translation
import { useTranslation } from "../../i18n";
import { useLocalStorage } from "../../hooks";

// Components
import { StyledNavLink } from "./styles";

const LanguageButton = () => {
  const [_, setLocale] = useLocalStorage("locale");
  const { t, i18n } = useTranslation("navbar");

  const isEnglish = i18n.language === "en";

  const switchLanguage = () => {
    const language = isEnglish ? "ar" : "en";
    i18n.changeLanguage(language);
    setLocale(language);
  };

  return (
    <Box fontFamily={isEnglish ? "Dinar" : "Gotham"}>
      <StyledNavLink component={Link} underline="none" onClick={switchLanguage}>
        {t("language")}
      </StyledNavLink>
    </Box>
  );
};

export default LanguageButton;
